import { React, useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  Col,
  Button,
  Form,
  Modal,
  Row,
  Collapse,
  Container
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { HiUserAdd } from 'react-icons/hi';

import { AuthWizardContext, StoreContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import { showToast } from '../Toast/toast';
import { removeParamsFunc } from '../Hooks/useRemoveParams';
import SelectTax from '../Search/SelectTax';
import SelectSalesType from '../Search/SelectSalesType';
import SelectCurrency from '../Search/SelectCurrency';
import SelectPaymentTerms from '../Search/SelectPaymentTerm';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import ErrorAlert from '../Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import SelectCreditStatus from 'components/form/SelectCreditStatus';
import EmiratesIDReaderButton from 'components/emirates-id-reader/EmiratesIDReaderButton';
import FormLayer from '../Layer/FormLayer';

function AddCustomer({ show, onHide, setFormData, data }) {
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [isFetch, setIsFetch] = useState(false);
  const [customerData, setCustomerData] = useState({
    name: '',
    address: '',
    email: '',
    phone: '',
    trn_no: '',
    tax_id: user?.branch?.sales_settings?.tax_id ?? '',
    tax_id_ref: user?.branch?.sales_settings?.tax_name ?? '',
    currency_code: user?.branch?.currency_name ?? '',
    currency_code_ref: user?.branch?.currency_name
      ? {
          label: user?.branch?.currency_name,
          value: user?.branch?.currency_name
        }
      : '',
    sales_type_id: user?.branch?.sales_settings?.sales_type_id ?? '',
    sales_type_id_ref: {
      label: user?.branch?.sales_settings?.sales_type_name ?? '',
      value: user?.branch?.sales_settings?.sales_type_id ?? ''
    },
    credit_status_id: user?.branch?.sales_settings?.credit_status_id ?? '',
    credit_status_id_ref:
      user?.branch?.sales_settings?.credit_status_name ?? '',
    payment_term_id: user?.branch?.sales_settings?.payment_term_id ?? '',
    payment_term_id_ref: {
      label: user?.branch?.sales_settings?.payment_term_name ?? '',
      value: user?.branch?.sales_settings?.payment_term_id ?? ''
    },
    contacts: []
  });
  const [showMore, setShowMore] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  useEffect(() => {
    setCustomerData(prev => ({
      ...prev,
      name: data
    }));
  }, [data]);

  const handleTax = (valueObject, action) => {
    let taxId = valueObject && valueObject.value ? valueObject.value : null;
    if (taxId && action.action === 'select-option') {
      setCustomerData(prev => ({
        ...prev,
        tax_id: taxId,
        tax_id_ref: valueObject
      }));
    } else {
      setCustomerData(prev => ({
        ...prev,
        tax_id: '',
        tax_id_ref: ''
      }));
    }
    setFormError({});
  };

  const handleSalesType = (valueObject, action) => {
    let salesTypeId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (salesTypeId && action.action === 'select-option') {
      setCustomerData(prev => ({
        ...prev,
        sales_type_id: salesTypeId,
        sales_type_id_ref: valueObject
      }));
    } else {
      setCustomerData(prev => ({
        ...prev,
        sales_type_id: '',
        sales_type_id_ref: ''
      }));
    }
    setFormError({});
  };

  const handleCreditStatus = (valueObject, action) => {
    let creditStatusId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (creditStatusId && action.action === 'select-option') {
      setCustomerData(prev => ({
        ...prev,
        credit_status_id: creditStatusId,
        credit_status_id_ref: valueObject
      }));
    } else {
      setCustomerData(prev => ({
        ...prev,
        credit_status_id: '',
        credit_status_id_ref: ''
      }));
    }
    setFormError({});
  };

  const handlePaymentTerms = (valueObject, action) => {
    let paymentTermId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (paymentTermId && action.action === 'select-option') {
      setCustomerData(prev => ({
        ...prev,
        payment_term_id: paymentTermId,
        payment_term_id_ref: valueObject
      }));
    } else {
      setCustomerData(prev => ({
        ...prev,
        payment_term_id: '',
        payment_term_id_ref: ''
      }));
    }
    setFormError({});
  };

  const handleCurrency = (valueObject, action) => {
    let currencyCodeId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (currencyCodeId && action.action === 'select-option') {
      setCustomerData(prev => ({
        ...prev,
        currency_code: currencyCodeId,
        currency_code_ref: valueObject
      }));
    } else {
      setCustomerData(prev => ({
        ...prev,
        currency_code: ''
      }));
    }
    setFormError({});
  };

  const handleFieldChange = e => {
    setCustomerData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    axios({
      method: 'post',
      url: 'sales/customers',
      data: customerData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          setCustomerData({
            name: '',
            address: '',
            email: '',
            phone: '',
            trn_no: '',
            tax_id: null,
            tax_id_ref: null,
            currency_code: null,
            sales_type_id: null,
            sales_type_id_ref: null,
            credit_status_id: null,
            credit_status_id_ref: null,
            payment_term_id: null,
            payment_term_id_ref: null,
            contacts: []
          });
          setOnSave(false);
          setShowMore(false);
          onHide();
          setFormData(prev => ({
            ...prev,
            customer_id: response.data.data.id,
            customer_id_ref: {
              label: response.data.data.name,
              value: response.data.data.id
            },
            email: response?.data?.data?.email,
            mobile: response?.data?.data?.phone,
            phone: response?.data?.data?.phone,
            sales_type_id: response?.data?.data?.sales_type_id,
            sales_type_id_ref: response?.data?.data?.sales_type_id
              ? {
                  label: response?.data?.data?.sales_type_name,
                  value: response?.data?.data?.sales_type_id
                }
              : '',
            name: response.data.data.name
          }));
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          console.log(error);
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    navigate(
      `?${createSearchParams(
        removeParamsFunc(allQueryParams, ['add_new_customer'])
      )}`
    );
    onHide();
    setShowMore(false);
    setFormError({});
  };

  const handleClearForm = () => {
    setCustomerData({
      name: '',
      address: '',
      email: '',
      phone: '',
      trn_no: '',
      tax_id: null,
      tax_id_ref: null,
      currency_code: null,
      sales_type_id: null,
      sales_type_id_ref: null,
      credit_status_id: null,
      credit_status_id_ref: null,
      payment_term_id: null,
      payment_term_id_ref: null,
      contacts: []
    });
  };

  useLayoutEffect(() => {
    const populateData = () => {
      setCustomerData(prev => ({
        ...prev,
        tax_id: user?.branch?.sales_settings?.tax_id ?? '',
        tax_id_ref: {
          label: user?.branch?.sales_settings?.tax_name ?? '',
          value: user?.branch?.sales_settings?.tax_id ?? ''
        },
        currency_code: user?.branch?.currency_name ?? '',
        currency_code_ref: user?.branch?.currency_name
          ? {
              label: user?.branch?.currency_name,
              value: user?.branch?.currency_name
            }
          : '',
        sales_type_id: user?.branch?.sales_settings?.sales_type_id ?? '',
        sales_type_id_ref: {
          label: user?.branch?.sales_settings?.sales_type_name ?? '',
          value: user?.branch?.sales_settings?.sales_type_id ?? ''
        },
        credit_status_id: user?.branch?.sales_settings?.credit_status_id ?? '',
        credit_status_id_ref: {
          label: user?.branch?.sales_settings?.credit_status_name ?? '',
          value: user?.branch?.sales_settings?.credit_status_id ?? ''
        },
        payment_term_id: user?.branch?.sales_settings?.payment_term_id ?? '',
        payment_term_id_ref: {
          label: user?.branch?.sales_settings?.payment_term_name ?? '',
          value: user?.branch?.sales_settings?.payment_term_id ?? ''
        }
      }));
    };

    if (show) {
      populateData();
    }
  }, [show, user]);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay form-layer-container"
      onHide={handleCancel}
    >
      {isFetch ? <FormLayer caption={'Reading Emirates ID'} /> : ''}
      <Card className="p-0">
        <Modal.Header
          className="d-flex flex-row align-items-center border-bottom"
          closeButton
        >
          <HiUserAdd size={25} className="text-dark me-1 m-0" />
          <h5 className="modal-form-heading me-2">
            {Translate('Add Customer')}
          </h5>
          <EmiratesIDReaderButton
            setFormData={setCustomerData}
            variant="warning"
            setIsFetch={setIsFetch}
          />
        </Modal.Header>
        <Form>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Row>
              <Form.Group as={Col} md={6} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label className="require-data">
                      {Translate('Name')}
                    </Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="name"
                          className="input"
                          onChange={handleFieldChange}
                          value={customerData.name}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.name ? (
                          <FormErrorPopover
                            id="name"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.name}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Phone')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <PhoneNumberInput
                          name="phone"
                          onChange={handleFieldChange}
                          value={customerData.phone}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.phone ? (
                          <FormErrorPopover
                            id="phone"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.phone}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Email')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={handleFieldChange}
                          value={customerData.email}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.email ? (
                          <FormErrorPopover
                            id="email"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.email}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Tax')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <SelectTax
                          value={customerData.tax_id_ref}
                          name={customerData.tax_id_ref}
                          handleFieldChange={handleTax}
                          setFormData={setCustomerData}
                          disableAddButton
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.tax_id ? (
                          <FormErrorPopover
                            id="tax_id"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.tax_id}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label className="require-data">
                      {Translate('Currency Code')}
                    </Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <SelectCurrency
                          value={customerData.currency_code_ref}
                          name={customerData.currency_code_ref}
                          handleFieldChange={handleCurrency}
                          isClearable={false}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.currency_code ? (
                          <FormErrorPopover
                            id="currency_code"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.currency_code}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group md={6} sm={12} className="mb-3" as={Col}>
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label className=" m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate(
                        `${store?.globalConfiguration?.tax_label} Number`
                      )}
                    </Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="trn_no"
                          onChange={handleFieldChange}
                          value={customerData.trn_no}
                        />
                      </Col>
                      <FormErrorPopover
                        id="trn_no"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.trn_no}
                      />
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            <Button
              variant="light"
              size="sm"
              type="button"
              className="w-40 d-flex  flex-column mt-0 mb-3 p-0 "
              style={{
                boxShadow: 'none',
                paddingRight: '20px'
              }}
              onClick={() => setShowMore(!showMore)}
              aria-controls="showMore"
              aria-expanded={showMore}
            >
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <h5
                  className="fs--1 text-info m-0"
                  style={{ color: 'royalblue' }}
                >
                  {showMore ? Translate('Show Less') : Translate('Show More')}
                </h5>
              </div>
            </Button>
            <Collapse in={showMore}>
              <Container className="p-0">
                <Row id="showMore">
                  <Form.Group as={Col} md={6} sm={12} className="mb-3">
                    <Row>
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>{Translate('Sales Type')}</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row className="d-flex flex-row">
                          <Col
                            xl={11}
                            md={11}
                            sm={11}
                            className="pe-sm-0 flex-2"
                          >
                            <SelectSalesType
                              value={customerData.sales_type_id_ref}
                              name={customerData.sales_type_id_ref}
                              handleFieldChange={handleSalesType}
                              setFormData={setCustomerData}
                              disableAddButton
                            />
                          </Col>
                          <Col
                            xl={1}
                            md={1}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                          >
                            {formError.sales_type_id ? (
                              <FormErrorPopover
                                id="sales_type_id"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.sales_type_id}
                              />
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12} className="mb-3">
                    <Row>
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>{Translate('Credit Status')}</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row className="d-flex flex-row">
                          <Col
                            xl={11}
                            md={11}
                            sm={11}
                            className="pe-sm-0 flex-2"
                          >
                            <SelectCreditStatus
                              value={customerData.credit_status_id_ref}
                              name={'credit_status_id'}
                              handleFieldChange={handleCreditStatus}
                            />
                          </Col>
                          <Col
                            xl={1}
                            md={1}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                          >
                            {formError.credit_status_id ? (
                              <FormErrorPopover
                                id="credit_status_id"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.credit_status_id}
                              />
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12} className="mb-3">
                    <Row>
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>{Translate('Payment Terms')}</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row className="d-flex flex-row">
                          <Col
                            xl={11}
                            md={11}
                            sm={11}
                            className="pe-sm-0 flex-2"
                          >
                            <SelectPaymentTerms
                              value={customerData.payment_term_id_ref}
                              name={customerData.payment_term_id_ref}
                              handleFieldChange={handlePaymentTerms}
                              setFormData={setCustomerData}
                              disableAddButton
                            />
                          </Col>
                          <Col
                            xl={1}
                            md={1}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                          >
                            {formError.payment_term_id ? (
                              <FormErrorPopover
                                id="payment_term_id"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.payment_term_id}
                              />
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group as={Col} md={6} sm={12} className="mb-3">
                    <Row>
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>{Translate('Address')}</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row className="d-flex flex-row">
                          <Col
                            xl={11}
                            md={11}
                            sm={11}
                            className="pe-sm-0 flex-2"
                          >
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="address"
                              onChange={handleFieldChange}
                              value={customerData.address}
                            />
                          </Col>
                          <Col
                            xl={1}
                            md={1}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                          >
                            {formError.address ? (
                              <FormErrorPopover
                                id="address"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.address}
                              />
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Container>
            </Collapse>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <SaveButton
              handleSubmit={handleSubmit}
              onSave={onSave}
              createPermission={true}
              editPermission={true}
            />
            <CancelButton
              handleCancel={handleClearForm}
              onSave={onSave}
              clear={true}
            />
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

AddCustomer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func,
  data: PropTypes.any
  // setData: PropTypes.func
};

export default AddCustomer;
