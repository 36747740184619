import { React, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StoreContext, AuthWizardContext } from 'context/Context';
import eventEmitter from './eventEmiter';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';

const InitStore = ({ children }) => {
  const [loadComplete, setLoadComplete] = useState(false);
  let { user } = useContext(AuthWizardContext);
  const { addIitemToStore } = useContext(StoreContext);
  const showMultiBranch = getAcodaxPermissionSlug('BRANCH', 'switch');
  const showUserBranch = getAcodaxPermissionSlug('BRANCH', 'enable-filter');
  const stringifiedUser = JSON.stringify(user || {});

  const init = useCallback(async () => {
    const parsedUser = JSON.parse(stringifiedUser);
    const getCurrentDate = () => {
      const current = new Date();
      const currentDate = `${current.getFullYear()}-${(
        '0' +
        (current.getMonth() + 1)
      ).slice(-2)}-${('0' + current.getDate()).slice(-2)}`;
      addIitemToStore('currentDate', currentDate);
    };

    const getLatestVersion = async () => {
      try {
        const res = await axios.get('app-versions/latest/react');
        if (res?.data?.success && res?.data?.data) {
          addIitemToStore('latestVersion', res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const globalConfiguration = () => {
      const {
        branch,
        whatsapp_web_api_enabled,
        branch_id,
        enable_crm_link_in_navbar
      } = parsedUser || {};
      const {
        finance_settings = {},
        sales_settings = {},
        system_settings = {},
        purchase_settings = {},
        inventory_settings = {},
        base_currency = 'AED'
      } = branch || {};

      addIitemToStore('globalConfiguration', {
        tax_label: finance_settings?.tax_label ?? 'TRN',
        round_off_max_limit: finance_settings?.round_off_max_limit ?? '0',
        round_off_precision: finance_settings?.round_off_precision ?? '0.00',
        round_off_algorithm: finance_settings?.round_off_algorithm ?? 'auto',
        show_multi_branch_for_admins: showMultiBranch,
        show_nav_bar_crm_link: enable_crm_link_in_navbar ?? false,
        enable_whatsapp_bot: whatsapp_web_api_enabled ?? false,
        enable_fixed_assets: system_settings?.enable_fixed_assets,
        enable_emirates_id_reader: system_settings?.enable_emirates_id_reader,
        show_multi_branch_for_users: showUserBranch,
        enable_approval_transaction:
          system_settings?.enable_approval_transaction ?? '0',
        enable_inter_branch_transaction: true,
        branch_id,
        purchase_settings,
        base_currency,
        system_settings: system_settings ?? '',
        sales_settings: {
          general: {
            show_warehouse_wise_item_stock:
              sales_settings?.show_warehouse_wise_item_stock === '1'
          },
          sd: {
            show_price_on_sales_delivery:
              sales_settings?.show_price_on_sales_delivery ?? '0'
          }
        },
        inventory_settings: {
          show_in_print_document:
            inventory_settings?.show_in_print_document ?? ''
        },
        undeposited_cheque_account: finance_settings?.undeposited_cheque_account
          ? {
              value: finance_settings?.undeposited_cheque_account,
              label: finance_settings?.undeposited_cheque_account_name
            }
          : ''
      });
    };

    if (!loadComplete) {
      getCurrentDate();
      await getLatestVersion();
      globalConfiguration();
      setLoadComplete(true);
    }
  }, [
    addIitemToStore,
    loadComplete,
    showMultiBranch,
    stringifiedUser,
    showUserBranch
  ]);

  useEffect(() => {
    const parsedUser = JSON.parse(stringifiedUser);
    init();
    const handleChannelData = data => {
      if (data?.message === 'settings.updated') {
        let finance_settings = data?.data?.branch?.finance_settings;
        let sales_settings = data?.data?.branch?.sales_settings;
        let system_settings = data?.data?.branch?.system_settings;
        let purchase_settings = data?.data?.branch?.purchase_settings;
        let inventory_settings = parsedUser?.branch?.inventory_settings;
        addIitemToStore('globalConfiguration', {
          tax_label: finance_settings?.tax_label ?? 'TRN',
          round_off_max_limit: finance_settings?.round_off_max_limit ?? '0',
          round_off_precision: finance_settings?.round_off_precision ?? '0.00',
          round_off_algorithm: finance_settings?.round_off_algorithm ?? 'auto',
          show_multi_branch_for_admins: showMultiBranch,
          enable_whatsapp_bot: parsedUser?.whatsapp_web_api_enabled ?? false,
          show_multi_branch_for_users: showUserBranch,
          show_nav_bar_crm_link: parsedUser?.enable_crm_link_in_navbar ?? false,
          enable_fixed_assets: system_settings?.enable_fixed_assets,
          enable_emirates_id_reader: system_settings?.enable_emirates_id_reader,
          enable_approval_transaction:
            system_settings.enable_approval_transaction ?? '0',
          enable_inter_branch_transaction: true,
          system_settings: system_settings ?? '',
          purchase_settings: purchase_settings,
          branch_id: data?.data?.branch_id,
          base_currency: data?.data?.branch?.base_currency ?? 'AED',
          sales_settings: {
            general: {
              show_warehouse_wise_item_stock:
                sales_settings?.show_warehouse_wise_item_stock === '1'
                  ? true
                  : false
            },
            sd: {
              show_price_on_sales_delivery:
                sales_settings?.show_price_on_sales_delivery ?? '0'
            }
          },
          inventory_settings: {
            show_in_print_document:
              inventory_settings?.show_in_print_document ?? ''
          },
          undeposited_cheque_account:
            finance_settings?.undeposited_cheque_account
              ? {
                  value: finance_settings?.undeposited_cheque_account,
                  label: finance_settings?.undeposited_cheque_account_name
                }
              : ''
        });
      }
    };
    eventEmitter.on('settingsEmitter', handleChannelData);
    return () => {
      eventEmitter.off('settingsEmitter', handleChannelData);
    };
  }, [addIitemToStore, init, showMultiBranch, stringifiedUser, showUserBranch]);

  return !loadComplete ? (
    <Container
      className="d-flex align-items-center justify content-center"
      style={{ height: '100vh', width: '100vw' }}
    >
      <LoadingScreen />
    </Container>
  ) : (
    children
  );
};

InitStore.propTypes = {
  children: PropTypes.element.isRequired
};

export default InitStore;
