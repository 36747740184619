// import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import Select from 'react-select';
// import axios from 'axios';
// import isObject from 'helpers/isObject';
// import Fuse from 'fuse.js';

// const SelectAccountDescriptions = ({
//   handleFieldChange,
//   name,
//   error,
//   value,
//   keyName,
//   style,
//   placeholder,
//   activeAccountCode,
//   filter_keys = {},
//   url = 'finance/chart-of-accounts-by-group',
//   ...rest
// }) => {
//   const [loading, setloading] = useState(false);
//   const [optionsArray, setoptionsArray] = useState([]);

//   const findAndSetActiveAccountCodeData = useCallback(
//     (activeAccountCode, optionsArray) => {
//       if (activeAccountCode?.length > 0 && Array.isArray(optionsArray)) {
//         const activeAccount = optionsArray
//           .reduce((newResult, accountData) => {
//             newResult = [...newResult, ...accountData.options];
//             return newResult;
//           }, [])
//           .find(account => account.code === activeAccountCode);

//         if (typeof handleFieldChange === 'function') {
//           handleFieldChange(activeAccount, {
//             name: name
//           });
//         }
//       }
//     },
//     [handleFieldChange, name]
//   );

//   const filterKeysString = JSON.stringify(
//     isObject(filter_keys) ? filter_keys : {}
//   );
//   const initFunc = useCallback(
//     async activeAccountCode => {
//       setloading(true);
//       try {
//         let response = await axios.get(url, {
//           params: JSON.parse(filterKeysString)
//         });
//         let resultArrayOptions = (
//           Array.isArray(response?.data) ? response.data : []
//         ).map(item => ({
//           label: item.name,
//           options: item.data.map(value => ({
//             label: value.code + ' - ' + value.name,
//             code: value.code,
//             value: keyName ? value[keyName] : value.id
//           }))
//         }));

//         findAndSetActiveAccountCodeData(activeAccountCode, resultArrayOptions);

//         setoptionsArray(resultArrayOptions);
//       } catch (error) {
//         console.log(error);
//       }
//       setloading(false);
//     },
//     [filterKeysString, findAndSetActiveAccountCodeData, keyName, url]
//   );

//   useEffect(() => {
//     if (activeAccountCode?.length > 0) {
//       if (!Array.isArray(optionsArray) || optionsArray?.length < 1) {
//         initFunc(activeAccountCode);
//       } else {
//         findAndSetActiveAccountCodeData(activeAccountCode, optionsArray);
//       }
//     }
//   }, [
//     activeAccountCode,
//     optionsArray,
//     findAndSetActiveAccountCodeData,
//     initFunc
//   ]);

//   const Option = ({ innerProps, children, isSelected }) => {
//     return (
//       <div
//         {...innerProps}
//         className={isSelected ? '' : 'drop-down-menu'}
//         style={{
//           backgroundColor: isSelected ? '#2C7BE5' : 'white',
//           color: isSelected ? 'white' : '',
//           fontWeight: '12px',
//           marginTop: '1px',
//           paddingTop: '5px',
//           paddingBottom: '5px',
//           paddingLeft: '20px',
//           cursor: 'pointer'
//         }}
//       >
//         {children}
//       </div>
//     );
//   };

//   Option.propTypes = {
//     innerProps: PropTypes.any,
//     children: PropTypes.any,
//     isSelected: PropTypes.any
//   };

//   const GroupHeading = ({ children }) => {
//     return (
//       <div
//         style={{
//           fontSize: '15px',
//           fontWeight: 'bold',
//           padding: '5px',
//           backgroundColor: '#fef6f0'
//         }}
//       >
//         {children}
//       </div>
//     );
//   };

//   GroupHeading.propTypes = {
//     children: PropTypes.any
//   };

//   const customStyle = {
//     menuPortal: provided => ({ ...provided, zIndex: 9999 }),
//     menu: provided => ({
//       ...provided,
//       zIndex: 9999,
//       fontSize: '13px',
//       borderRadius: '0px'
//     }),
//     control: provided => ({
//       ...provided,
//       height: 32,
//       minHeight: 32,
//       ...style
//     })
//   };

//   const fuse = new Fuse(
//     optionsArray.flatMap(group => group.options),
//     {
//       keys: ['label', 'code'],
//       threshold: 0.4
//     }
//   );

//   const filterOption = (option, inputValue) => {
//     if (!inputValue) return true;

//     const results = fuse.search(inputValue).map(({ item }) => item.value);
//     return results.includes(option.value);
//   };

//   return (
//     <Select
//       isClearable
//       isLoading={loading}
//       onFocus={initFunc}
//       placeholder={placeholder ?? ''}
//       options={optionsArray}
//       value={value}
//       onChange={handleFieldChange}
//       filterOption={filterOption}
//       name={name ? name : 'account_description'}
//       classNamePrefix="app-react-select"
//       className={error && 'is-invalid'}
//       styles={customStyle}
//       menuPortalTarget={document.body}
//       components={{ Option, GroupHeading }}
//       {...rest}
//     />
//   );
// };

// SelectAccountDescriptions.propTypes = {
//   handleFieldChange: PropTypes.func,
//   name: PropTypes.string,
//   error: PropTypes.any,
//   value: PropTypes.any,
//   keyName: PropTypes.string,
//   style: PropTypes.object,
//   placeholder: PropTypes.string,
//   activeAccountCode: PropTypes.string || undefined,
//   filter_keys: PropTypes.object,
//   url: PropTypes.string
// };

// export default SelectAccountDescriptions;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
import isObject from 'helpers/isObject';
import Fuse from 'fuse.js';
import debounce from 'lodash.debounce';

const SelectAccountDescriptions = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  activeAccountCode,
  filter_keys = {},
  url = 'finance/chart-of-accounts-by-group',
  ...rest
}) => {
  const [loading, setloading] = useState(false);
  const [optionsArray, setoptionsArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const findAndSetActiveAccountCodeData = useCallback(
    (activeAccountCode, optionsArray) => {
      if (activeAccountCode?.length > 0 && Array.isArray(optionsArray)) {
        const activeAccount = optionsArray
          .reduce((newResult, accountData) => {
            newResult = [...newResult, ...accountData.options];
            return newResult;
          }, [])
          .find(account => account.code === activeAccountCode);

        if (typeof handleFieldChange === 'function') {
          handleFieldChange(activeAccount, {
            name: name
          });
        }
      }
    },
    [handleFieldChange, name]
  );

  const filterKeysString = JSON.stringify(
    isObject(filter_keys) ? filter_keys : {}
  );
  const initFunc = useCallback(
    async activeAccountCode => {
      setloading(true);
      try {
        let response = await axios.get(url, {
          params: JSON.parse(filterKeysString)
        });
        let resultArrayOptions = (
          Array.isArray(response?.data) ? response.data : []
        ).map(item => ({
          label: item.name,
          options: item.data.map(value => ({
            label: value.code + ' - ' + value.name,
            code: value.code,
            value: keyName ? value[keyName] : value.id
          }))
        }));

        findAndSetActiveAccountCodeData(activeAccountCode, resultArrayOptions);

        setoptionsArray(resultArrayOptions);
      } catch (error) {
        console.log(error);
      }
      setloading(false);
    },
    [filterKeysString, findAndSetActiveAccountCodeData, keyName, url]
  );

  useEffect(() => {
    if (activeAccountCode?.length > 0) {
      if (!Array.isArray(optionsArray) || optionsArray?.length < 1) {
        initFunc(activeAccountCode);
      } else {
        findAndSetActiveAccountCodeData(activeAccountCode, optionsArray);
      }
    }
  }, [
    activeAccountCode,
    optionsArray,
    findAndSetActiveAccountCodeData,
    initFunc
  ]);

  const Option = ({ innerProps, children, isSelected }) => {
    return (
      <div
        {...innerProps}
        className={isSelected ? '' : 'drop-down-menu'}
        style={{
          backgroundColor: isSelected ? '#2C7BE5' : 'white',
          color: isSelected ? 'white' : '',
          fontWeight: '12px',
          marginTop: '1px',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '20px',
          cursor: 'pointer'
        }}
      >
        {children}
      </div>
    );
  };

  Option.propTypes = {
    innerProps: PropTypes.any,
    children: PropTypes.any,
    isSelected: PropTypes.any
  };

  const GroupHeading = ({ children }) => {
    return (
      <div
        style={{
          fontSize: '15px',
          fontWeight: 'bold',
          padding: '5px',
          backgroundColor: '#fef6f0'
        }}
      >
        {children}
      </div>
    );
  };

  GroupHeading.propTypes = {
    children: PropTypes.any
  };

  const customStyle = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided,
      zIndex: 9999,
      fontSize: '13px',
      borderRadius: '0px'
    }),
    control: provided => ({
      ...provided,
      height: 32,
      minHeight: 32,
      ...style
    })
  };

  const fuse = useMemo(() => {
    return new Fuse(
      optionsArray.flatMap(group => group.options),
      {
        keys: ['label', 'code'],
        threshold: 0.18,
        findAllMatches: true
      }
    );
  }, [optionsArray]);

  const filterOption = (option, inputValue) => {
    if (!inputValue) return true;
    const results = fuse.search(searchTerm).map(({ item }) => item.value);
    return results.includes(option.value);
  };

  // eslint-disable-next-line
  const handleInputChange = useCallback(
    debounce(value => {
      setSearchTerm(value);
      setloading(false);
    }, 1000),
    []
  );

  const onInputChange = value => {
    setloading(true);
    handleInputChange(value);
  };

  return (
    <Select
      isClearable
      isLoading={loading}
      onFocus={initFunc}
      placeholder={placeholder ?? ''}
      options={optionsArray}
      value={value}
      onChange={handleFieldChange}
      onInputChange={onInputChange}
      filterOption={filterOption}
      name={name ? name : 'account_description'}
      classNamePrefix="app-react-select"
      className={error && 'is-invalid'}
      styles={customStyle}
      menuPortalTarget={document.body}
      components={{ Option, GroupHeading }}
      {...rest}
    />
  );
};

SelectAccountDescriptions.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  activeAccountCode: PropTypes.string || undefined,
  filter_keys: PropTypes.object,
  url: PropTypes.string
};

export default SelectAccountDescriptions;
